<template>
    <v-menu
        :value="show"
        bottom
        :close-on-content-click="false"
        dense>
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :class="buttonClass"
                :color="color"
                icon
                small>
                <!-- <v-icon small>mdi-theme-light-dark</v-icon> -->
            </v-btn>
        </template>

        <v-card>
            <v-card-text>
                <v-color-picker v-model="colorVal" />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="save">Apply</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        data() {
            return {
                colorVal: this.value,
                show: null
            }
        },
        props: {
            buttonClass: {
                type: String,
                default: null
            },
            color: {
                type: String,
                default: null
            },
            openToggle: {
                type: Boolean,
                default: false
            },
            returnHex: {
                type: Boolean,
                default: false
            },
            value: null
        },
        watch: {
            // colorVal(v) {
            //     this.$emit('input', v)
            // }
            openToggle() {
                this.show = !this.show
            },
            value(v) {
                this.colorVal = v
            }
        },
        methods: {
            save() {
                var v = this.colorVal;

                if (typeof v != 'string') {
                    v = v.hex;
                }

                this.$emit('input', v);
                this.$emit('change')
                this.show = false;
            }
        }
    }
</script>